import { createAsyncThunk } from '@reduxjs/toolkit';
import { FallbackAlphaPoPaymentErrorMessage } from 'constants/notification-messages/fallback-alpha-po-payment-error-message';
import { requestAlphaPoPayment } from 'store/api/cuverse-api/alpha-po-payment/alpha-po-payment.service';
import {
  IAlphaPoPaymentResponseData,
  TAlphaPoMessage,
} from 'store/api/cuverse-api/alpha-po-payment/types/payment-card-api-data';

import { notifyError } from 'utils/notify/notify.utils';

export const fetchAlphaPoPaymentLinksThunkAction = createAsyncThunk(
  'alpha-po-payment/fetchAlphaPoPaymentLinks',
  async (id: number): Promise<TAlphaPoMessage> => {
    return await requestAlphaPoPayment({
      purchase_id: id,
      payment_method: 'alpha-po',
      operation_type: 'pool_miner',
      language: 'en',
    })
      .then((response: IAlphaPoPaymentResponseData) => {
        if (!response?.message) {
          notifyError(FallbackAlphaPoPaymentErrorMessage.FailedToGetPaymentLink);
          throw new Error();
        }

        return response.message;
      })
      .catch((error) => {
        throw error;
      });
  },
);
