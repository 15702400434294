import React, { FC } from 'react';
import dayjs from 'dayjs';
import { rowTitles } from 'pages/PageAffiliateProgram/constants/referral-history.consts';
import { IReferralHistoryResponseDataMapped } from 'store/api/cuverse-api/affiliate/types/referral-history-response.interface';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';

import * as S from 'pages/PageAffiliateProgram/components/ui/HistoryTableDesktop/HistoryTableDesktop.styled';

interface IHistoryTableDesktopProps {
  items: IReferralHistoryResponseDataMapped[];
  isLoading: boolean;
}

export const HistoryTableDesktop: FC<IHistoryTableDesktopProps> = ({ items, isLoading }) => {
  return (
    <S.TableWrapper>
      {isLoading ? (
        <SSpinnerBlue />
      ) : (
        <S.Table as="table">
          <thead>
            <tr>
              {rowTitles.map(({ title }) => (
                <S.HeadCellTh key={title}>{title}</S.HeadCellTh>
              ))}
            </tr>
          </thead>
          <tbody>
            {items.map((referralData) => {
              return (
                <S.Tr key={referralData.userId}>
                  {rowTitles.map(({ title, key, isDate, name }) => (
                    <td key={title}>
                      {isDate
                        ? `${dayjs.utc(referralData.dataTimestamp).format('DD.MM.YYYY')}`
                        : referralData[key]}{' '}
                      {name ?? null}
                    </td>
                  ))}
                </S.Tr>
              );
            })}
          </tbody>
        </S.Table>
      )}
    </S.TableWrapper>
  );
};
