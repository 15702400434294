import styled from 'styled-components';
import { FontFamily } from 'styles/style-variables/font-family';

export const TableWrapper = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background-color: #fff;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
`;

export const Tr = styled.tr`
  display: flex;
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  color: #272727;
`;

export const RowTitle = styled.td`
  width: 50%;
  color: #646464;
`;

export const RowValue = styled.td`
  width: 50%;
  color: #272727;
`;

export const Hr = styled.hr`
  margin: 0;
  opacity: 0.3;
  color: #a1a1a1;
  stroke-width: 1px;
`;
