import { createSlice } from '@reduxjs/toolkit';
import { TFetchStatus } from 'types/api/fetch-status.type';

import {fetchAlphaPoPaymentLinksThunkAction} from "./alpha-po-payment.thunk-actions";

export interface IAlphaPoPaymentState {
  link: string | null;
  linkFetchStatus: TFetchStatus;
  error: string | null;
}

const initialState: IAlphaPoPaymentState = {
  link: '',
  linkFetchStatus: 'initial',
  error: null
};

const alphaPoPaymentSlice = createSlice({
  name: 'alpha-po-payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlphaPoPaymentLinksThunkAction.pending, (state) => {
        state.linkFetchStatus = 'pending';
      })
      .addCase(fetchAlphaPoPaymentLinksThunkAction.fulfilled, (state, action) => {
        state.linkFetchStatus = 'fulfilled';
        state.link = action.payload;
      })
      .addCase(fetchAlphaPoPaymentLinksThunkAction.rejected, (state, error) => {
        state.linkFetchStatus = 'rejected';
        state.error = JSON.stringify(error);
      });
  },
});

export const alphaPoPaymentReducer = alphaPoPaymentSlice.reducer;
