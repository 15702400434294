import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { InvoiceScreen } from 'pages/PagePurchase/components/ui/InvoiceScreen';
import { MinersScreen } from 'pages/PagePurchase/components/ui/MinersScreen';
import { PaymentOptionsScreen } from 'pages/PagePurchase/components/ui/PaymentOptionsScreen';
import { PaymentSuccessScreen } from 'pages/PagePurchase/components/ui/PaymentSuccessScreen';
import { EPromoCodeStatus } from 'pages/PagePurchase/constants/promo-code-status.const';
import { IInvoiceBodyContent } from 'pages/PagePurchase/types/invoice-body-content.interface';
import { EPaymentType } from 'pages/PagePurchase/types/payment-type.enum';
import { IPromoCodeAreaValue } from 'pages/PagePurchase/types/promo-code-area-value.interface';
import { selectAlphaPoPaymentLink } from 'store/alpha-po-payment/alpha-po-payment.selectors';
import { fetchAlphaPoPaymentLinksThunkAction } from 'store/alpha-po-payment/alpha-po-payment.thunk-actions';
import {
  selectDashboardElectricity,
  selectDashboardWallet,
} from 'store/dashboard-reducer/dashboard.selectors';
import {
  requestDashboardElectricity,
  requestDashboardWalletBalance,
} from 'store/dashboard-reducer/dashboard.thunk-actions';
import { setCartPromoStatusAction } from 'store/invoice-reducer/invoice.reducer';
import {
  selectCartDiscountType,
  selectCartPromoStatus,
  selectInvoiceData,
} from 'store/invoice-reducer/invoice.selectors';
import {
  applyCartPromoThunkAction,
  fetchInvoiceThunkAction,
} from 'store/invoice-reducer/invoice.thunk-actions';
import {
  openAdditionalTermsPopupAction,
  openDepositElectricityPopupAction,
} from 'store/modals-reducer/modals.reducer';
import { fetchPaymentThunkAction } from 'store/payment-reducer/payment.thunk-actions';
import { requestProfileDataThunkAction } from 'store/profile-reducer/profile.thunk-actions';
import { setPurchaseStepAction } from 'store/purchase-reducer/purchase.reducer';
import {
  selectActiveMiners,
  selectCart,
  selectPurchaseStep,
} from 'store/purchase-reducer/purchase.selectors';
import {
  fetchActiveMinersThunkAction,
  fetchCartThunkAction,
} from 'store/purchase-reducer/purchase.thunk-actions';
import { selectBtcUsdRate } from 'store/rates-reducer/rates.selectors';
import { fetchPairRatesThunkAction } from 'store/rates-reducer/rates.thunk-actions';
import { useAppDispatch } from 'store/store';

import { getTotalFeePendingMiners } from 'utils/common/get-total-fee-pending-miners.utils';
import {
  gtmClickCartCheckout,
  gtmClickPaymentSubmit,
  gtmClickReplenishPaid,
} from 'utils/gtmSender/gtmSender';

import { usePurchaseBrowserBackClick } from './usePurchaseBrowserBackClick';

export const usePurchaseBody = (): IInvoiceBodyContent => {
  const dispatch = useAppDispatch();

  const purchaseStep = useSelector(selectPurchaseStep);
  const minersData = useSelector(selectActiveMiners);
  const cartData = useSelector(selectCart);
  const btcToUsdRate = useSelector(selectBtcUsdRate);
  const { balance } = useSelector(selectDashboardElectricity);
  const invoiceData = useSelector(selectInvoiceData);
  const promoStatus = useSelector(selectCartPromoStatus);
  const promoDiscountType = useSelector(selectCartDiscountType);
  const alphaPoPaymentLink = useSelector(selectAlphaPoPaymentLink);

  let invoiceBodyContent = null;
  const { cartId, totalCartSum, cartItems } = cartData;
  const { amount } = useSelector(selectDashboardWallet);
  const isCartExist = !!cartId;
  const totalDailyElectricityFeePendingMiners = getTotalFeePendingMiners(minersData, cartItems);

  const gtmItems = minersData.map((minerData) => {
    const cartItem = cartItems.find((item) => item.productId === minerData.id);
    return {
      item_name: minerData.model,
      item_id: minerData.id,
      price: minerData.price,
      item_brand: minerData.model.split(' ')[0],
      item_hash_rate: minerData.hashrate,
      item_electricity_fee: minerData.dailyElectricityFee,
      quantity: cartItem ? cartItem.quantity : 0,
    };
  });
  const itemsInCart = gtmItems.filter((el) => el.quantity);
  const gtmEcommerce = {
    transaction_id: invoiceData?.id,
    value: totalCartSum,
    coupon: 'not set',
    payment_type: 'BTC transfer',
    items: itemsInCart,
  };

  const handleCheckoutClick = () => {
    void dispatch(fetchInvoiceThunkAction(cartId));
    void dispatch(requestProfileDataThunkAction());
    gtmClickCartCheckout(itemsInCart);
  };

  const handlePaymentSubmit = () => {
    if (invoiceData?.id) {
      dispatch(setPurchaseStepAction('payment-options'));
    }
  };

  const handleBitcoinPayment = () => {
    if (invoiceData?.id) {
      void dispatch(fetchPaymentThunkAction(invoiceData.id));
      gtmClickPaymentSubmit(gtmEcommerce);
    }
  };

  const handlePaymentButtonClick = (paymentType: EPaymentType) => {
    if (paymentType === EPaymentType.BTC) {
      handleBitcoinPayment();
    }

    if (paymentType === EPaymentType.CRYPTO) {
      void dispatch(fetchAlphaPoPaymentLinksThunkAction(invoiceData?.id || 0));
    }

    // if (paymentType === EPaymentType.CARD) {
    //   console.log('Payed with card');
    // }
  };

  const handlePromoCodeButtonClick = () =>
    dispatch(setCartPromoStatusAction(EPromoCodeStatus.Form));

  const handlePromoCodeSubmit = ({ promoCode }: IPromoCodeAreaValue) =>
    void dispatch(applyCartPromoThunkAction({ purchaseId: cartId, couponCode: promoCode }));

  const handleCloseButtonClick = () => dispatch(setCartPromoStatusAction(EPromoCodeStatus.Form));

  const handleAdditionalTermsClick = () => dispatch(openAdditionalTermsPopupAction());

  const handleReplenishButtonClick = () => {
    dispatch(openDepositElectricityPopupAction());
    gtmClickReplenishPaid();
  };

  useScrollToTop(purchaseStep);
  usePurchaseBrowserBackClick(purchaseStep);

  useEffect(() => {
    void dispatch(fetchPairRatesThunkAction());
    void dispatch(requestDashboardElectricity());
    void dispatch(requestDashboardWalletBalance());
    dispatch(setPurchaseStepAction('buying'));
    dispatch(setCartPromoStatusAction(EPromoCodeStatus.Button));
  }, [dispatch]);

  useEffect(() => {
    if (purchaseStep === 'buying') {
      void dispatch(fetchActiveMinersThunkAction());
      void dispatch(fetchCartThunkAction());
    }
  }, [dispatch, purchaseStep]);

  useEffect(() => {
    if (alphaPoPaymentLink) {
      window.location.href = alphaPoPaymentLink;
    }
  }, [alphaPoPaymentLink]);

  switch (purchaseStep) {
    case 'buying':
      invoiceBodyContent = (
        <MinersScreen
          minersData={minersData}
          totalCartSum={totalCartSum}
          cartList={cartItems}
          isCartExist={isCartExist}
          btcToUsdRate={btcToUsdRate}
          handleCheckoutClick={handleCheckoutClick}
          cartId={cartId}
        />
      );
      break;
    case 'checkout':
      invoiceBodyContent = (
        <InvoiceScreen
          purchaseStep={purchaseStep}
          handlePaymentSubmit={handlePaymentSubmit}
          cartList={cartItems}
          sumUsd={totalCartSum}
          finalSumBtc={invoiceData?.priceBtc || 0}
          discount={invoiceData?.discount || 0}
          discountType={promoDiscountType}
          btcEarned={amount}
          isPaymentSubmitting={false}
          promoStatus={promoStatus}
          handlePromoCodeButtonClick={handlePromoCodeButtonClick}
          handlePromoCodeSubmit={handlePromoCodeSubmit}
          handleCloseButtonClick={handleCloseButtonClick}
          handleAdditionalTermsClick={handleAdditionalTermsClick}
        />
      );
      break;
    case 'payment-options':
      invoiceBodyContent = (
        <PaymentOptionsScreen
          handlePaymentButtonClick={handlePaymentButtonClick}
          amount={invoiceData?.priceBtc || 0}
          balance={balance}
        />
      );
      break;
    case 'paid-success':
      invoiceBodyContent = (
        <PaymentSuccessScreen
          purchaseStep={purchaseStep}
          launchDate={invoiceData?.startAt}
          paymentBalanceUsd={balance}
          handleReplenishClick={handleReplenishButtonClick}
          sumUsd={totalCartSum}
          finalSumBtc={invoiceData?.priceBtc || 0}
          discount={invoiceData?.discount || 0}
          discountType={promoDiscountType}
          btcEarned={amount}
          promoStatus={promoStatus}
          electricityFee={totalDailyElectricityFeePendingMiners}
        />
      );
  }

  return { invoiceBodyContent };
};
