import {cuverseApi} from "store/api/cuverse-api/cuverse.api";

import {IAlphaPoPaymentRequestBody, IAlphaPoPaymentResponseData} from "./types/payment-card-api-data";
import {AlphaPoPaymentRoute} from "./alpha-po-payment.route";

export const requestAlphaPoPayment = async (
  body: IAlphaPoPaymentRequestBody,
): Promise<IAlphaPoPaymentResponseData> =>
  cuverseApi.post(AlphaPoPaymentRoute.Payment(), body).then((res) => {
    return res?.data;
  });