import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import {IAlphaPoPaymentState} from "./alpha-po-payment.reducer";

const getAlphaPoPaymentState = (state: TRootState): IAlphaPoPaymentState => state.alphaPoPaymentReducer;

export const selectAlphaPoPaymentLink = createSelector(
  [getAlphaPoPaymentState],
  (state) => state.link,
);

export const selectAlphaPoPaymentLinkError = createSelector(
  [getAlphaPoPaymentState],
  (state) => state.error,
);

export const selectAlphaPoPaymentLinkFetchStatus = createSelector(
  [getAlphaPoPaymentState],
  (state) => state.linkFetchStatus,
);
